import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.DE,
  locale: Locales['de-DE'],
  currency: Currencies.EUR,
  distanceUnit: DistanceUnit.km,
  dateFormat: {
    long: 'EEEE, d. MMMM y', // Adds a dot after the day as per the German standard, date-fns doesn't seem to add it automatically
    midsize: 'dd MMMM yyyy',
    short: 'dd/MM/yyyy',
  },
}

type PossibleLocales = keyof Pick<typeof Locales, 'de-DE'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'de-DE': INTL_DEFAULTS,
}
