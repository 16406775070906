import type { DeliveryIntervalConfiguration } from '@grandvisionhq/fun-products'
import { Locales } from '@grandvisionhq/state'

export const subscriptionDeliveryIntervalConfiguration: DeliveryIntervalConfiguration = {
  [Locales['de-DE']]: {
    '30 Tage': 30,
    '45 Tage': 45,
    '60 Tage': 60,
    '90 Tage': 90,
    '120 Tage': 120,
    '180 Tage': 180,
  },
}
