import { Util } from '@grandvisionhq/forms'

export const isDHLPostalNumber = (val: string): boolean => REGEX.DHL_POSTAL_NUMBER.test(val)

export const isValidStreetName = (val: string) => Boolean(/\w.*\d/.exec(val))

export const isPostalCode =
  (country: string) =>
  (value: string): boolean =>
    Util.validations.isValidPostalCodeForCountry(country, value)

const REGEX = {
  PHONE_NUMBER: /^(\+351|00351|351)?\d{9}$/,
  MOBILE_PHONE_NUMBER: /^(\+351|00351|351)([9]{1})(6|3|2|1{1})([0-9]{7})$/,
  DHL_POSTAL_NUMBER: /^\d{6,10}$/,
  STREET_NUMBER: /[0-9a-z]+$/i,
}
