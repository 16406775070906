import getConfig from 'next/config'

import { Intent, PaypalClientConfigurationContext } from '@grandvisionhq/payments'

import { isPostalCode } from '../util/form-validation'

const { publicRuntimeConfig } = getConfig()

const paypal = publicRuntimeConfig.paypal ?? {}

export const getPaypalConfig = (country: string): PaypalClientConfigurationContext => {
  const allowedCountries = ['DE']
  return {
    options: {
      clientId: paypal.clientId,
      disableFunding: paypal.disableFunding,
      intent: Intent.CAPTURE,
      vault: false,
      ...(paypal.environment === 'production' ? {} : { 'buyer-country': country }),
    },
    addressValidation: {
      postalCode: isPostalCode(country),
      countryCode: (value: string) => country == value || allowedCountries.includes(value),
    },
    buttonShape: 'pill',
  }
}
